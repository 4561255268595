<template>
    <span class="p-text-primary"><i class="pi pi-ellipsis-h icon-button ml-2" @click="abrirModal" title="Detalhar"></i></span>
    <Dialog header="Descrição da Realização" v-model:visible="mostrarModal" :style="{ width: '50vw' }">
        <p>
            {{ descRealizacao }}
        </p>
        <template #footer>
            <Button label="Fechar" icon="pi pi-times" @click="fecharModal" class="p-button-text" />
        </template>
    </Dialog>
</template>

<script>
export default {
    props: {
        descRealizacao: {
            type: String,
        },
    },

    data() {
        return {
            mostrarModal: false,
        };
    },

    methods: {
        abrirModal() {
            this.mostrarModal = true;
        },

        fecharModal() {
            this.mostrarModal = false;
        },
    },
};
</script>