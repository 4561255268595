<template>
    <painel titulo="Histórico do Cliente" icone="pi pi-users" :refreshFunction="atualizar">
        <tabela
            :data="historico"
            dataKey="dataHoraInicioRealizacao"
            :globalFilterFields="[
                'instrumento',
                'abordagem',
                'descTipoRealizacao',
                'nomeRealizacao',
                'descRealizacao',
                'codCliente',
                'cliente',
                'codEmpreendimento',
                'empreendimento',
                'mesAnoCompetencia',
            ]"
            stateKey="dt-crm-clientes-historico"
        >
            <template #conteudo>
                <Column field="dataHoraInicioRealizacao" header="Início Realização" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataHoraInicioRealizacao, 'DD/MM/YYYY HH:mm') }}
                    </template>
                </Column>
                <Column field="dataHoraFimRealizacao" header="Fim Realização" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataHoraFimRealizacao, 'DD/MM/YYYY HH:mm') }}
                    </template>
                </Column>
                <Column field="instrumento" header="Instrumento" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.instrumento }}
                    </template>
                </Column>
                <Column field="abordagem" header="Abordagem" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.abordagem }}
                    </template>
                </Column>
                <Column field="nomeRealizacao" header="Nome Realizacao" :sortable="true">
                    <template #body="slotProps">
                        <span v-tooltip.bottom="`Tipo da Realização: ${slotProps.data.descTipoRealizacao}`">{{ slotProps.data.nomeRealizacao }}</span>
                    </template>
                </Column>
                <Column field="descRealizacao" header="Descrição Realização" :sortable="true">
                    <template #body="slotProps">
                        <span>{{ slotProps.data.resumoDescRealizacao }}</span
                        ><btn-realizacao :descRealizacao="slotProps.data.descRealizacao"></btn-realizacao>
                    </template>
                </Column>
                <Column field="cliente" header="Cliente" :sortable="true">
                    <template #body="slotProps">
                        <span v-tooltip.bottom="`CodCliente: ${slotProps.data.codCliente}`"> {{ slotProps.data.cliente }}</span>
                    </template>
                </Column>
                <Column field="empreendimento" header="Empreendimento" :sortable="true">
                    <template #body="slotProps">
                        <span v-tooltip.bottom="`CodEmpreendimento: ${slotProps.data.codEmpreendimento}`"> {{ slotProps.data.empreendimento }}</span>
                    </template>
                </Column>
                <Column field="mesAnoCompetencia" header="Mês/Ano Competência" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.mesAnoCompetencia }}
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
</template>

<script>
import Services from '../services';
import BtnRealizacao from './BtnRealizacao';

export default {
    data() {
        return {
            historico: null,
        };
    },

    methods: {
        obterHistorico() {
            this.$store.dispatch('addRequest');

            let filtro = {};

            if (this.$route.query.codSebrae && !this.$route.query.codsebrae) {
                filtro.codSebrae = this.$route.query.codSebrae;
            } else if (!this.$route.query.codSebrae && this.$route.query.codsebrae) {
                filtro.codSebrae = this.$route.query.codsebrae;
            }

            if (this.$route.query.codParceiro && !this.$route.query.codparceiro) {
                filtro.codParceiro = this.$route.query.codParceiro;
            } else if (!this.$route.query.codParceiro && this.$route.query.codparceiro) {
                filtro.codParceiro = this.$route.query.codparceiro;
            }

            Services.obterHistorico(filtro).then((response) => {
                if (response?.success) {
                    this.historico = response.data;
                } else {
                    this.historico = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterHistorico();
        },
    },

    created() {
        this.$store.dispatch('trocarMenu');
        this.obterHistorico();
    },

    watch: {
        '$route.query'() {
            this.obterHistorico();
        },
    },

    components: {
        BtnRealizacao,
    },
};
</script>